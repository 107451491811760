html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

.App {
  text-align: center;
  margin: auto;
}

.carousel-indicators button {
  background-color: rgb(185, 185, 185);  
  border: 1px solid rgb(173, 173, 173);
  width: 20px;
  height: 5px; 
  margin: 0 5px;
  border-radius: 2px;
}

.carousel-indicators button.active {
  background-color: rgb(221, 237, 234);
  border-color: rgb(143, 227, 213)
}

.filter-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
}

.pageWrapper {
  padding: 20px 0;
}

.fullScreenLoadingWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  z-index: 10000;
}

.fadingOut {
  animation: fadeOut 1s ease-in-out;
  animation-fill-mode: forwards;
}

.fullScreenLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  font-size: 50px;
  width: 100%;
  height: 100%;
  color: #45b09a;
  --base-delay: 1.25s; /* Base delay for the first child */
}

.fullScreenLoader .paw {
  width: 1em;
  height: 1em;
  animation: var(--animation-duration) pawAnimation ease-in-out infinite;
  opacity: 0;
  animation-delay: calc(var(--base-delay) - (0.25s * (var(--paw-index) - 1)));
}

.fullScreenLoader .paw svg {
  width: 100%;
  height: 100%;
}

.fullScreenLoader .paw .icon {
  fill: currentColor;
  transform: rotate(90deg);
}

.fullScreenLoader .paw:nth-child(odd) {
  transform: rotate(-10deg) translateY(-0.6em);
}

.fullScreenLoader .paw:nth-child(even) {
  transform: rotate(10deg) translateY(0.6em);
}

.no-cssanimations .fullScreenLoader .paw {
  opacity: 1;
}

@-webkit-keyframes pawAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes pawAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}